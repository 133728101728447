<template>
  <form v-if="form" class="space-y-6 w-full">

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 pt-4">
      <div class="col-span-1">
        <Autocomplete placeholder="Meklēt klientu no kataloga" v-model="form.customer" :errors="errors.customer" :params="{ with_inventory_items: true }" @autocompleteItem="getCustomer" url="/api/fetch_customers" :additionalFields="['address', 'reg_nr']" />
      </div>
    </div>

    <template v-if="form.customer">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 pt-4">
        <div class="col-span-2">
          <div class="mt-2 sm:flex flex-wrap gap-y-2 w-full">
            <ItemText title="Klients" :text="form.customer.name" />
            <ItemText title="Reģ. nr" :text="form.customer.reg_nr" />
          </div>
        </div>
        <div class="col-span-1">
          <Select v-model="form.customerBranch" :errors="errors.customerBranch" :items="form.customer.branches" placeholder="Filiāle" />
        </div>
      </div>
    </template>

    <div class="pt-2">
      <div class="flex justify-end">
        <button type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2" @click="hideAddItemForm">
          Atcelt
        </button>

        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Saglabāt
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Select from "@/components/Components/Select";
import Autocomplete from "@/components/Components/Autocomplete";
import ItemText from "@/components/Components/ItemText";

export default {
  name: "AddCustomerForm",
  components: {
    Loading,
    Autocomplete,
    ItemText,
    Select
  },
  data: () => ({
    form: {
      customer: null,
      customerBranch: null,
      autocompleteValue: null
    }
  }),
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      errors: "errors",
    }),
    formValidation() {
      return {
        customer: {
          rules: ['required']
        },
        customerBranch: {
          rules: [this.form.customer ? 'required' : null]
        }
      }
    }
  },
  watch: {
    customer() {
      if (this.form.customer) {
        if(this.form.customer.branches.length > 0) {
          this.form.customerBranch = this.form.customer.branches[0]
        }
      }
    }
  },
  methods: {
    getCustomer(value) {
      this.form.customer = value
    },
    hideAddItemForm() {
      this.$store.dispatch("removeAllFormsForDisplay");
    },
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch('updateOrder', {
          id: this.order.id,
          data: {
            customer_id: this.form.customer.id,
            branch_id: this.form.customerBranch.id,
            status_id: this.order.status.id
          }
        })
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>

<style>

</style>